<template>
  <div id="disable-right-click" class="detail-content margin-content">
    <div class="dc-header">
      <b-container class="d-flex align-items-center">
        <router-link
          :to="{
            name: 'library',
          }"
        >
          <div>コンテンツ</div>
        </router-link>
        <b-icon icon="chevronRight" class="mx-3"></b-icon>
        <router-link
          :to="{
            name: 'DetailContentLibrary',
            params: { idContent: 1 },
          }"
        >
          <div>目次</div>
        </router-link>
        <b-icon icon="chevronRight" class="mx-3"></b-icon>
        <div>{{ titleCata }}</div>
      </b-container>
    </div>
    <div class="dc-main d-flex flex-column" style="position: relative">
      <div class="dc-main-list ml-auto mt-5" style="width: 100%">
        <div class="accordion accordion-page" role="tablist">
          <b-collapse
            :id="'accordionId_1'"
            visible
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body class="px-0">
              <div
                class="w-100"
                v-for="(itemBody, indexBody) in page_Content.blocks"
                :key="indexBody"
              >
                <div
                  :id="itemBody.id"
                  class="item-data w-100"
                  v-if="itemBody.type === 'paragraph'"
                  v-html="itemBody.data.text"
                  :class="
                    itemBody.tunes && itemBody.idChild
                      ? itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'toggle-block__item text-center'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'toggle-block__item text-right'
                        : 'toggle-block__item text-left'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'text-center my-3'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'text-right my-3'
                      : ' text-left my-3'
                  "
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                ></div>
                <div
                  :id="itemBody.id"
                  class="item-data w-100"
                  v-if="itemBody.type === 'tableOfContents'"
                >
                  <h3>目次</h3>
                  <ul class="list-unstyled pl-4 listTableOfContent">
                    <li
                      v-for="(itemTOC, index) in itemBody.data.items"
                      :key="index"
                      class="pl-3"
                    >
                      <a
                        style="cursor: pointer"
                        v-scroll-to="{ el: '#' + itemTOC.href, offset: -20 }"
                        v-html="itemTOC.text"
                      ></a>
                    </li>
                  </ul>
                </div>
                <div
                  :id="itemBody.id"
                  class="item-data w-100"
                  v-if="itemBody.type === 'header'"
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <h1
                    v-if="itemBody.data.level === 1"
                    class="mb-0"
                    :class="
                      itemBody.tunes && itemBody.idChild
                        ? itemBody.tunes.anyTuneName.alignment === 'center'
                          ? 'toggle-block__item text-center'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'right'
                          ? 'toggle-block__item text-right'
                          : 'toggle-block__item text-left'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'text-center my-3'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'text-right my-3'
                        : ' text-left my-3'
                    "
                    v-html="itemBody.data.text"
                  ></h1>
                  <h2
                    v-if="itemBody.data.level === 2"
                    class="mb-0"
                    :class="
                      itemBody.tunes && itemBody.idChild
                        ? itemBody.tunes.anyTuneName.alignment === 'center'
                          ? 'toggle-block__item text-center'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'right'
                          ? 'toggle-block__item text-right'
                          : 'toggle-block__item text-left'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'text-center my-3'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'text-right my-3'
                        : ' text-left my-3'
                    "
                    v-html="itemBody.data.text"
                  ></h2>
                  <h3
                    v-if="itemBody.data.level === 3"
                    class="mb-0"
                    :class="
                      itemBody.tunes && itemBody.idChild
                        ? itemBody.tunes.anyTuneName.alignment === 'center'
                          ? 'toggle-block__item text-center'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'right'
                          ? 'toggle-block__item text-right'
                          : 'toggle-block__item text-left'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'text-center my-3'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'text-right my-3'
                        : ' text-left my-3'
                    "
                    v-html="itemBody.data.text"
                  ></h3>
                  <h4
                    v-if="itemBody.data.level === 4"
                    class="mb-0"
                    :class="
                      itemBody.tunes && itemBody.idChild
                        ? itemBody.tunes.anyTuneName.alignment === 'center'
                          ? 'toggle-block__item text-center'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'right'
                          ? 'toggle-block__item text-right'
                          : 'toggle-block__item text-left'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'text-center my-3'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'text-right my-3'
                        : ' text-left my-3'
                    "
                    v-html="itemBody.data.text"
                  ></h4>
                  <h5
                    v-if="itemBody.data.level === 5"
                    class="mb-0"
                    :class="
                      itemBody.tunes && itemBody.idChild
                        ? itemBody.tunes.anyTuneName.alignment === 'center'
                          ? 'toggle-block__item text-center'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'right'
                          ? 'toggle-block__item text-right'
                          : 'toggle-block__item text-left'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'text-center my-3'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'text-right my-3'
                        : ' text-left my-3'
                    "
                    v-html="itemBody.data.text"
                  ></h5>
                  <h6
                    v-if="itemBody.data.level === 6"
                    class="mb-0"
                    :class="
                      itemBody.tunes && itemBody.idChild
                        ? itemBody.tunes.anyTuneName.alignment === 'center'
                          ? 'toggle-block__item text-center'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'right'
                          ? 'toggle-block__item text-right'
                          : 'toggle-block__item text-left'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'text-center my-3'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'text-right my-3'
                        : ' text-left my-3'
                    "
                    v-html="itemBody.data.text"
                  ></h6>
                </div>
                <div
                  :id="itemBody.id"
                  class="item-data w-100"
                  v-if="itemBody.type === 'list'"
                  :class="
                    itemBody.idChild ? 'toggle-block__item text-left' : 'my-3'
                  "
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <ul class="w-100" style="list-style-type: decimal">
                    <li
                      v-for="(itemList, indexList) in itemBody.data.items"
                      :key="indexList"
                      class="mb-2"
                      v-html="itemList"
                    ></li>
                  </ul>
                </div>
                <div
                  class="item-data w-100"
                  v-if="itemBody.type === 'toggle'"
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <div
                    class="w-100 d-flex align-items-center toggle-block__selector"
                    :id="itemBody.id"
                  >
                    <span
                      class="icon-toggle-panel mr-2"
                      @click="toggleBlock(itemBody)"
                    >
                      <i
                        :class="
                          itemBody.data.status === 'open'
                            ? 'fas fa-caret-down'
                            : 'fas fa-caret-right'
                        "
                      ></i>
                    </span>
                    <span v-html="itemBody.data.text"></span>
                  </div>
                </div>
                <!-- <div
                  class="item-data w-100"
                  v-if="itemBody.type === 'toggle'"
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <div
                    class="w-100 d-flex align-items-center toggle-block__selector"
                    :id="itemBody.id"
                  >
                    <span
                      class="icon-toggle-panel mr-2"
                      @click="toggleBlock(itemBody)"
                    >
                      <i
                        :class="
                          itemBody.data.status === 'open'
                            ? 'fas fa-caret-down'
                            : 'fas fa-caret-right'
                        "
                      ></i>
                    </span>
                    {{ itemBody.data.text }}
                  </div>
                </div> -->
                <div
                  :id="itemBody.id"
                  class="item-data w-100"
                  v-if="itemBody.type === 'code'"
                  :class="
                    itemBody.idChild ? 'toggle-block__item text-left' : 'my-3'
                  "
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <div v-html="itemBody.data.code"></div>
                </div>
                <div
                  :id="itemBody.id"
                  class="item-data w-100 my-3"
                  v-if="itemBody.type === 'raw'"
                  :class="
                    itemBody.idChild ? 'toggle-block__item text-left' : ''
                  "
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <div v-html="itemBody.data.html"></div>
                  <!-- <code>{{ itemBody.data.html }}</code> -->
                </div>
                <div
                  :id="itemBody.id"
                  class="item-data w-100"
                  v-if="itemBody.type === 'table'"
                  :class="
                    itemBody.idChild ? 'toggle-block__item text-left' : 'my-3'
                  "
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th
                          v-for="(itemTblTh, indexTblTh) in itemBody.data
                            .content[0]"
                          :key="indexTblTh"
                          class="border-bottom-0"
                        >
                          <span class="w-100" v-html="itemTblTh"></span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(itemTblTr, indexTblTr) in itemBody.data
                          .contentTbody"
                        :key="indexTblTr"
                      >
                        <td
                          v-for="(itemTblTd, indexTblTd) in itemTblTr"
                          :key="indexTblTd"
                        >
                          <span class="w-100" v-html="itemTblTd"></span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div
                  :id="itemBody.id"
                  class="item-data w-100"
                  v-if="itemBody.type === 'checklist'"
                  :class="
                    itemBody.idChild ? 'toggle-block__item text-left' : 'my-3'
                  "
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <div
                    class="custom-control custom-checkbox"
                    v-for="(itemCheckbox, indexCheckbox) in itemBody.data.items"
                    :key="indexCheckbox"
                  >
                    <input
                      :id="'checkbox-' + itemBody.id + indexCheckbox"
                      type="checkbox"
                      :name="'checkbox-' + itemBody.id + indexCheckbox"
                      class="custom-control-input"
                      :checked="itemCheckbox.checked"
                    />
                    <label
                      :for="'checkbox-' + itemBody.id + indexCheckbox"
                      class="custom-control-label ml-1"
                    >
                      <span v-html="itemCheckbox.text"></span>
                    </label>
                  </div>
                </div>
                <div
                  :id="itemBody.id"
                  class="item-data w-100"
                  v-if="itemBody.type === 'warning'"
                  :class="
                    itemBody.idChild ? 'toggle-block__item text-left' : 'my-3'
                  "
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <div class="w-100 bg-warning p-3 text-white">
                    <div class="w-100 font-weight-bold">
                      {{ itemBody.data.title }}
                    </div>
                    <hr />
                    <div class="w-100">
                      {{ itemBody.data.message }}
                    </div>
                  </div>
                </div>
                <div
                  :id="itemBody.id"
                  class="item-data w-100"
                  v-if="itemBody.type === 'quote'"
                  :class="
                    itemBody.idChild ? 'toggle-block__item text-left' : 'my-3'
                  "
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <blockquote
                    class="otro-blockquote"
                    :class="
                      itemBody.data.alignment === 'left'
                        ? 'text-left'
                        : 'text-right'
                    "
                  >
                    <span>
                      {{ itemBody.data.caption }}
                    </span>
                    {{ itemBody.data.text }}
                  </blockquote>
                </div>
                <div
                  :id="itemBody.id"
                  class="item-data w-100"
                  v-if="itemBody.type === 'headerStyle'"
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                  :class="
                    itemBody.tunes && itemBody.idChild
                      ? itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'toggle-block__item text-center'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'toggle-block__item text-right'
                        : 'toggle-block__item text-left'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'text-center my-3'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'text-right my-3'
                      : ' text-left my-3'
                  "
                >
                  <div class="titleStyle" :class="itemBody.data.class">
                    <h1 class="dataInput">
                      {{ itemBody.data.text }}
                    </h1>
                  </div>
                </div>
                <div
                  :id="itemBody.id"
                  class="item-data w-100"
                  v-if="itemBody.type === 'boxStylle'"
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                  :class="
                    itemBody.tunes && itemBody.idChild
                      ? itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'toggle-block__item text-center'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'toggle-block__item text-right'
                        : 'toggle-block__item text-left'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'text-center my-3'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'text-right my-3'
                      : ' text-left my-3'
                  "
                >
                  <div class="boxStyle" :class="itemBody.data.class">
                    <span
                      class="box-title px-2 border-0"
                      v-if="itemBody.data.title"
                      >{{ itemBody.data.title }}</span
                    >
                    <div
                      class="border-0 shadow-none bg-transparent h-auto form-control p-2 dataInput"
                      v-html="itemBody.data.text"
                    ></div>
                  </div>
                </div>
                <div
                  :id="itemBody.id"
                  class="item-data w-100"
                  v-if="itemBody.type === 'delimiter'"
                  :class="
                    itemBody.idChild ? 'toggle-block__item text-left' : 'my-3'
                  "
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <div class="w-100 text-center ce-delimiter"></div>
                </div>
                <div
                  :id="itemBody.id"
                  class="item-data w-100"
                  v-if="itemBody.type === 'image'"
                  :class="
                    itemBody.tunes && itemBody.idChild
                      ? itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'toggle-block__item text-center'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'toggle-block__item text-right'
                        : 'toggle-block__item text-left'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'text-center my-3'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'text-right my-3'
                      : ' text-left my-3'
                  "
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <!-- <img :src="itemBody.data.file.url" alt="" width="auto" /> -->
                  <a
                    v-if="replaceText(itemBody.data.linkImage)"
                    :href="itemBody.data.linkImage"
                  >
                    <img :src="itemBody.data.file.url" alt="" width="auto" />
                  </a>
                  <img
                    v-else
                    :src="itemBody.data.file.url"
                    alt=""
                    width="auto"
                  />
                  <h4 class="mt-2">{{ itemBody.data.caption }}</h4>
                </div>
                <div
                  :id="itemBody.id"
                  class="item-data w-100"
                  v-if="itemBody.type === 'attachesPDF'"
                  :class="
                    itemBody.idChild ? 'toggle-block__item text-left' : 'my-3'
                  "
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <ViewPdf
                    v-bind:pageNumber="lastPagePDF"
                    v-bind:timeWatching="timeWatchingEmbed"
                    v-bind:idLast="idLastPDF"
                    v-bind:src="itemBody.data.file.url"
                    v-bind:idComponent="itemBody.id"
                  />
                </div>
                <!-- <div
                  :id="itemBody.id"
                  class="item-data w-100"
                  v-if="itemBody.type === 'attachesPDF'"
                  :class="
                    itemBody.idChild ? 'toggle-block__item text-left' : 'my-3'
                  "
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <iframe
                    id="main-slide"
                    :src="
                      'https://azu.github.io/slide-pdf.js/?slide=' +
                      itemBody.data.file.url
                    "
                    scrolling="no"
                    allowtransparency="true"
                    width="100%"
                    height="500px"
                    style="border: 0"
                  >
                  </iframe>
                </div> -->
                <div
                  :id="itemBody.id"
                  class="item-data w-100"
                  :class="
                    itemBody.idChild ? 'toggle-block__item text-left' : 'my-3'
                  "
                  v-if="
                    itemBody.type === 'embed' &&
                    itemBody.data.service === 'youtube'
                  "
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <YoutubePlayer
                    v-bind:idElemYoutube="itemBody.id"
                    v-bind:idLastYoutube="idLastEmbed"
                    v-bind:timeWatching="timeWatchingEmbed"
                    v-bind:src="itemBody.data.source"
                    v-bind:heightPlayer="itemBody.data.height"
                    v-bind:widthPlayer="itemBody.data.width"
                    v-bind:dataVideo="itemBody.data"
                  />
                </div>
                <div
                  :id="itemBody.id"
                  class="item-data w-100"
                  :class="
                    itemBody.idChild ? 'toggle-block__item text-left' : 'my-3'
                  "
                  v-if="
                    itemBody.type === 'embed' &&
                    itemBody.data.service === 'vimeo'
                  "
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <VimeoPlayer
                    v-bind:idElemVimeo="itemBody.id"
                    v-bind:idLastVimeo="idLastEmbed"
                    v-bind:timeWatching="timeWatchingEmbed"
                    v-bind:src="itemBody.data.embed"
                    v-bind:heightPlayer="itemBody.data.height"
                    v-bind:widthPlayer="itemBody.data.width"
                    v-bind:dataVideo="itemBody.data"
                  />
                </div>
                <div
                  :id="itemBody.id"
                  class="item-data w-100"
                  v-if="itemBody.type === 'anyButton'"
                  :class="
                    itemBody.tunes && itemBody.idChild
                      ? itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'toggle-block__item text-center'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'toggle-block__item text-right'
                        : 'toggle-block__item text-left'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'text-center my-3'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'text-right my-3'
                      : ' text-left my-3'
                  "
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <!-- <a
                    v-if="itemBody.data.type === null"
                    :class="itemBody.data.style"
                    rel="nofollow noindex noreferrer"
                    target="_blank"
                    :href="itemBody.data.link"
                  >
                    {{ itemBody.data.text }}
                  </a> -->
                  <a
                    v-if="itemBody.data.type === null"
                    class="anyButton"
                    :class="itemBody.data.style"
                    @click="downloadFile($event, itemBody.data)"
                  >
                    {{ itemBody.data.text }}
                  </a>
                  <div
                    class="w-100"
                    v-if="
                      itemBody.data.type !== null &&
                      (itemBody.data.style === 'roundButton' ||
                        itemBody.data.style === 'socialGlossySmooth' ||
                        itemBody.data.style === 'socialSquare')
                    "
                    :class="
                      itemBody.tunes && itemBody.idChild
                        ? itemBody.tunes.anyTuneName.alignment === 'center'
                          ? 'toggle-block__item text-center'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'right'
                          ? 'toggle-block__item text-right'
                          : 'toggle-block__item text-left'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'text-center my-3'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'text-right my-3'
                        : ' text-left my-3'
                    "
                  >
                    <a
                      v-if="itemBody.data.twitter !== null"
                      :href="itemBody.data.twitter"
                      class="btn-social-circle btn-social-circle--twitter"
                      ><i class="fab fa-twitter"></i>
                    </a>
                    <a
                      v-if="itemBody.data.facebook !== null"
                      :href="itemBody.data.facebook"
                      class="btn-social-circle btn-social-circle--facebook"
                      ><i class="fab fa-facebook-f"></i>
                    </a>
                    <a
                      v-if="itemBody.data.pocket !== null"
                      :href="itemBody.data.pocket"
                      class="btn-social-circle btn-social-circle--pocket"
                      ><i class="fab fa-get-pocket"></i>
                    </a>
                    <a
                      v-if="itemBody.data.feedly !== null"
                      :href="itemBody.data.feedly"
                      class="btn-social-circle btn-social-circle--feedly"
                      ><i class="fas fa-rss"></i
                    ></a>
                  </div>
                  <div
                    class="w-100"
                    v-if="
                      itemBody.data.type !== null &&
                      (itemBody.data.style === 'socialGiza' ||
                        itemBody.data.style === 'socialSmartPhone')
                    "
                    :class="
                      itemBody.tunes && itemBody.idChild
                        ? itemBody.tunes.anyTuneName.alignment === 'center'
                          ? 'toggle-block__item text-center'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'right'
                          ? 'toggle-block__item text-right'
                          : 'toggle-block__item text-left'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'text-center my-3'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'text-right my-3'
                        : ' text-left my-3'
                    "
                  >
                    <div
                      :class="
                        itemBody.data.style === 'socialGiza'
                          ? 'btn-social-giza'
                          : 'btn-social-phone'
                      "
                    >
                      <a
                        href="1"
                        :class="
                          itemBody.data.style === 'socialGiza'
                            ? 'btn-social-giza-twitter'
                            : 'btn-social-phone-twitter ml-0 mr-3'
                        "
                        v-if="itemBody.data.twitter !== null"
                      >
                        <span class="fa-stack">
                          <i
                            class="fas fa-stack-2x"
                            :class="
                              itemBody.data.style === 'socialGiza'
                                ? 'fa-certificate'
                                : 'fa-tablet-alt'
                            "
                          ></i>
                          <i class="fab fa-twitter fa-stack-1x"></i>
                        </span>
                      </a>
                      <a
                        href="3535"
                        :class="
                          itemBody.data.style === 'socialGiza'
                            ? 'btn-social-giza-facebook'
                            : 'btn-social-phone-facebook ml-0 mr-3'
                        "
                        v-if="itemBody.data.facebook !== null"
                      >
                        <span class="fa-stack">
                          <i
                            class="fas fa-stack-2x"
                            :class="
                              itemBody.data.style === 'socialGiza'
                                ? 'fa-certificate'
                                : 'fa-tablet-alt'
                            "
                          ></i>
                          <i class="fab fa-facebook-f fa-stack-1x"></i>
                        </span>
                      </a>
                      <a
                        href="5467"
                        :class="
                          itemBody.data.style === 'socialGiza'
                            ? 'btn-social-giza-pocket'
                            : 'btn-social-phone-pocket ml-0 mr-3'
                        "
                        v-if="itemBody.data.pocket !== null"
                      >
                        <span class="fa-stack">
                          <i
                            class="fas fa-stack-2x"
                            :class="
                              itemBody.data.style === 'socialGiza'
                                ? 'fa-certificate'
                                : 'fa-tablet-alt'
                            "
                          ></i>
                          <i class="fab fa-get-pocket fa-stack-1x"></i>
                        </span>
                      </a>
                      <a
                        href="1"
                        :class="
                          itemBody.data.style === 'socialGiza'
                            ? 'btn-social-giza-feedly'
                            : 'btn-social-phone-feedly ml-0 mr-3'
                        "
                        v-if="itemBody.data.feedly !== null"
                      >
                        <span class="fa-stack">
                          <i
                            class="fas fa-stack-2x"
                            :class="
                              itemBody.data.style === 'socialGiza'
                                ? 'fa-certificate'
                                : 'fa-tablet-alt'
                            "
                          ></i>
                          <i class="fas fa-rss fa-stack-1x"></i>
                        </span>
                      </a>
                    </div>
                  </div>
                  <div
                    class="w-100"
                    v-if="
                      itemBody.data.type !== null &&
                      (itemBody.data.style === 'socialFlat' ||
                        itemBody.data.style === 'socialIsometric')
                    "
                    :class="
                      itemBody.tunes && itemBody.idChild
                        ? itemBody.tunes.anyTuneName.alignment === 'center'
                          ? 'toggle-block__item text-center'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'right'
                          ? 'toggle-block__item text-right'
                          : 'toggle-block__item text-left'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'text-center my-3'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'text-right my-3'
                        : ' text-left my-3'
                    "
                  >
                    <div>
                      <a
                        href="1"
                        :class="
                          itemBody.data.style === 'socialFlat'
                            ? 'btn-social-flat'
                            : 'btn-social-isometric'
                        "
                      >
                        <span
                          :class="
                            itemBody.data.style === 'socialFlat'
                              ? 'btn-social-flat-icon btn-social-isometric-icon--twitter'
                              : 'btn-social-isometric-icon btn-social-isometric-icon--twitter'
                          "
                        >
                          <i class="fab fa-twitter"></i>
                        </span>
                        <span
                          :class="
                            itemBody.data.style === 'socialFlat'
                              ? 'btn-social-flat-text'
                              : 'btn-social-isometric-text'
                          "
                          >TWEET</span
                        >
                      </a>
                    </div>
                    <div>
                      <a
                        href="1"
                        :class="
                          itemBody.data.style === 'socialFlat'
                            ? 'btn-social-flat'
                            : 'btn-social-isometric'
                        "
                      >
                        <span
                          :class="
                            itemBody.data.style === 'socialFlat'
                              ? 'btn-social-flat-icon btn-social-isometric-icon--facebook'
                              : 'btn-social-isometric-icon btn-social-isometric-icon--facebook'
                          "
                        >
                          <i class="fab fa-facebook"></i>
                        </span>
                        <span
                          :class="
                            itemBody.data.style === 'socialFlat'
                              ? 'btn-social-flat-text'
                              : 'btn-social-isometric-text'
                          "
                          >TWEET</span
                        >
                      </a>
                    </div>
                    <div>
                      <a
                        href="1"
                        :class="
                          itemBody.data.style === 'socialFlat'
                            ? 'btn-social-flat'
                            : 'btn-social-isometric'
                        "
                      >
                        <span
                          :class="
                            itemBody.data.style === 'socialFlat'
                              ? 'btn-social-flat-icon btn-social-isometric-icon--pocket'
                              : 'btn-social-isometric-icon btn-social-isometric-icon--pocket'
                          "
                        >
                          <i class="fab fa-get-pocket"></i>
                        </span>
                        <span
                          :class="
                            itemBody.data.style === 'socialFlat'
                              ? 'btn-social-flat-text'
                              : 'btn-social-isometric-text'
                          "
                          >TWEET</span
                        >
                      </a>
                    </div>
                    <div>
                      <a
                        href="1"
                        :class="
                          itemBody.data.style === 'socialFlat'
                            ? 'btn-social-flat'
                            : 'btn-social-isometric'
                        "
                      >
                        <span
                          :class="
                            itemBody.data.style === 'socialFlat'
                              ? 'btn-social-flat-icon btn-social-isometric-icon--feedly'
                              : 'btn-social-isometric-icon btn-social-isometric-icon--feedly'
                          "
                        >
                          <i class="fas fa-rss"></i>
                        </span>
                        <span
                          :class="
                            itemBody.data.style === 'socialFlat'
                              ? 'btn-social-flat-text'
                              : 'btn-social-isometric-text'
                          "
                          >TWEET</span
                        >
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  :id="itemBody.id"
                  class="item-data w-100 my-3"
                  v-if="itemBody.type === 'attachesFile'"
                >
                  <div class="w-100 d-flex box-file-upload">
                    <div
                      class="iconFile"
                      :data-extension="itemBody.data.file.extension"
                      :style="{ color: itemBody.data.file.color }"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="40"
                      >
                        <path
                          d="M17 0l15 14V3v34a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3h20-6zm0 2H3a1 1 0 0 0-1 1v34a1 1 0 0 0 1 1h26a1 1 0 0 0 1-1V14H17V2zm2 10h7.926L19 4.602V12z"
                        />
                      </svg>
                    </div>
                    <div class="fileName">
                      <div class="w-100">{{ itemBody.data.file.name }}</div>
                      <div
                        class="text-muted w-100"
                        v-html="convertSize(itemBody.data.file.size)"
                      ></div>
                    </div>
                    <div class="iconDowload ml-auto">
                      <a
                        class="iconDowload"
                        v-bind:download="itemBody.data.file.url"
                        rel="nofollow noindex noreferrer"
                        @click="downloadFileUpload($event, itemBody.data.file)"
                      >
                        <i class="fas fa-cloud-download-alt"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </b-card-body>
          </b-collapse>
          <!-- </b-card> -->
        </div>
      </div>
      <div
        class="card-content pt-3 row align-items-center justify-content-center"
      >
        <button
          v-on:click="returnPage()"
          class="float-right button-back-dashboard back-btn"
        >
          戻る
        </button>
      </div>
    </div>
    <!-- Modal List catalog -->
    <b-modal id="list-catalog" size="xl" title="目次" hide-footer>
      <CRow
        ><CCol sm="12">
          <div class="contents-list">
            <div
              class="contents-list-item"
              v-for="(item, index) in listCata"
              :key="index"
            >
              <router-link
                :to="{
                  name: 'DetailPageLibrary',
                  params: {
                    idPage: item.catalogue_id,
                    idContent: idURL,
                  },
                }"
                class="d-flex align-items-center"
              >
                <div class="icon-check">
                  <b-icon icon="check"></b-icon>
                </div>
                <div class="textName">{{ item.title }}</div>
              </router-link>
            </div>
          </div>
        </CCol></CRow
      >
    </b-modal>
    <!-- Modal List catalog -->
    <!-- Modal List Note -->
    <b-modal
      id="list-note"
      size="xl"
      title="コンテンツエリア
"
      hide-footer
    >
      <CRow
        ><CCol sm="12">
          <div class="list-note">
            <div class="d-flex justify-content-end">
              <b-button
                variant="primary"
                v-b-modal.modal-create-note
                @click="callStartGetCurrentEmbed"
                ><b-icon icon="plus" font-scale="2"></b-icon
                >メモを作成</b-button
              >
            </div>
            <div
              class="list-note-item my-2 position-relative"
              v-for="(item, index) in listNote"
              :key="index"
            >
              <div
                class="list-note-item-top my-2 d-flex align-items-center justify-content-between"
              >
                <div
                  class="title-item"
                  style="word-break: break-all; padding-right: 3%"
                >
                  {{ item.title_note }}
                </div>
                <div class="d-flex align-items-center">
                  <div class="time-item mx-2">
                    {{
                      new Date(item.created_at).toLocaleString("ja-JP", {
                        timeZone: "Asia/Tokyo",
                      })
                    }}
                  </div>
                  <div class="list-note-button">
                    <b-button
                      variant="warning"
                      class="mx-2"
                      @click="showModalEdit(item.id)"
                      ><b-icon
                        icon="pencil-square"
                        class="icon-note my-2"
                      ></b-icon>
                    </b-button>
                    <b-button
                      variant="danger"
                      class="mx-2"
                      v-on:click="showConfirm(item.id)"
                      v-b-modal.modal-delete-note
                      ><b-icon icon="trash" class="icon-note my-2"></b-icon
                    ></b-button>
                  </div>
                </div>
              </div>
              <div
                class="list-note-item-content d-flex"
                style="word-break: break-all"
              >
                {{ item.content_note }}
              </div>
              <div class="list-note-item-pdf d-flex flex-column">
                <div>コンテンツ： {{ item.page_title }}</div>
                <div>
                  PDF：{{ item.page_pdf === null ? 1 : item.page_pdf }}枚目
                </div>
                <div>
                  動画：{{
                    item.seconds_watch_video !== null
                      ? item.seconds_watch_video
                      : 0
                  }}秒
                </div>
              </div>
              <div
                class="list-tag d-flex flex-wrap my-3"
                v-if="item.tags.length > 0"
              >
                <div
                  class="item-tag mx-2 position-relative"
                  v-for="(item, index) in item.tags"
                  :key="index"
                >
                  {{ item.tag_name }}
                </div>
              </div>
            </div>
          </div>
        </CCol></CRow
      >
    </b-modal>
    <!-- Modal List Note -->
    <!-- Modal Create Note -->
    <b-modal
      id="modal-create-note"
      modal-class="modalCustom px-3"
      style="min-width: 720px"
    >
      <template #modal-header="{}">
        <h5 class="w-100" style="text-align: center">ノート</h5>
        <b-button
          size="sm"
          style="background-color: white; border-color: white"
          class="close"
          @click="closeModal()"
        >
          <i class="fas fa-times"></i>
        </b-button>
      </template>
      <CRow
        ><CCol sm="12">
          <input
            class="form-control hoverMouse"
            v-model="title"
            placeholder="タイトル"
            style="border: 1px solid #fff; height: 66px"
            :maxlength="50"
          /> </CCol
        ><CCol sm="12" class="pt-3">
          <textarea
            class="form-input col-sm-12 p-3 hoverMouse"
            type="text"
            v-model="content"
            placeholder="メモ"
            rows="4"
            style="border: 1px solid #fff"
            :maxlength="1000"
          /></CCol
        ><CCol sm="12" class="my-3">
          <!-- <div>コンテンツ：{{ detail_page_title }}</div> -->
          <div class="d-flex my-2">PDF：{{ page_pdf }} 枚目</div>
          <div class="d-flex my-2">動画：{{ seconds_watch_video }} 秒</div>
        </CCol>
        <CCol sm="12">
          <input
            class="form-control hoverMouse"
            v-model="tag"
            placeholder="登録するタグ名を入力し[Enter]"
            style="border: 1px solid #fff; height: 64px"
            v-on:keyup.enter="createTag"
          /> </CCol
        ><CCol sm="12" class="pt-3">
          <span class="title2">よく使うタグ</span>
          <div class="list-tag d-flex flex-wrap my-3 align-items-center">
            <div
              class="position-relative d-flex align-items-center px-1 py-1"
              v-for="(item, index) in listTagOptions"
              :key="index"
            >
              <a
                class="customTag2"
                :class="{ customA: item.checked === true }"
                v-on:click="ClickTagChecked(item, index)"
                ><span class="customSpan">{{ item.tag_name }}</span></a
              ><img
                v-if="item.checked === true"
                v-on:click="ClickTagChecked(item, index)"
                src="@/assets/img/bg_note_tag_active.png"
                style="width: 18px; height: 20px; cursor: pointer"
              />
              <img
                v-else
                v-on:click="ClickTagChecked(item, index)"
                src="@/assets/img/bg_note_tag.png"
                style="width: 18px; height: 20px; cursor: pointer"
              />
            </div>
            &ensp;&ensp;
          </div>
        </CCol></CRow
      >
      <template #modal-footer="{}">
        <div class="">
          <b-button
            v-on:click.prevent="CreateNewNote()"
            :disabled="isLoading"
            variant="info"
            class="mr-4"
            >ノート記録
          </b-button>
          <b-button
            variant="secondary"
            @click="closeModal()"
            class="mx-2 back-btn"
            >戻る</b-button
          >
        </div>
      </template>
    </b-modal>
    <!-- Modal Create Note -->
    <!-- Modal Edit Note -->
    <b-modal
      id="modal-edit-note"
      modal-class="modalCustom px-3"
      style="min-width: 720px"
    >
      <template #modal-header="{}">
        <h5 class="w-100" style="text-align: center">ノート</h5>
        <b-button
          size="sm"
          style="background-color: white; border-color: white"
          class="close"
          @click="closeModalEdit()"
        >
          <i class="fas fa-times"></i>
        </b-button>
      </template>
      <CRow
        ><CCol sm="12">
          <input
            class="form-control hoverMouse"
            v-model="detail_title"
            placeholder="タイトル"
            style="border: 1px solid #fff; height: 66px"
            :maxlength="50"
          /> </CCol
        ><CCol sm="12" class="pt-3">
          <textarea
            class="form-input col-sm-12 p-3 hoverMouse"
            type="text"
            v-model="detail_content"
            placeholder="メモ"
            rows="4"
            style="border: 1px solid #fff"
            :maxlength="1000"
          />
        </CCol>
        <CCol sm="12" class="my-3">
          <!-- <div>コンテンツ：{{ detail_page_title }}</div> -->
          <div class="d-flex my-2">PDF：{{ detail_pdf }} 枚目</div>
          <div class="d-flex my-2">動画：{{ detail_video }} 秒</div></CCol
        >
        <CCol sm="12">
          <input
            class="form-control hoverMouse"
            v-model="tag"
            placeholder="登録するタグ名を入力し[Enter]"
            style="border: 1px solid #fff; height: 64px"
            v-on:keyup.enter="createTag"
          /> </CCol
        ><CCol sm="12" class="pt-3">
          <span class="title2">よく使うタグ</span>
          <div class="list-tag d-flex flex-wrap my-3 align-items-center">
            <div
              class="position-relative d-flex align-items-center px-1 py-1"
              v-for="(item, index) in listTagOptions"
              :key="index"
            >
              <a
                class="customTag2"
                :class="{ customA: item.checked === true }"
                v-on:click="ClickTagChecked(item, index)"
                ><span class="customSpan">{{ item.tag_name }}</span></a
              ><img
                v-if="item.checked === true"
                v-on:click="ClickTagChecked(item, index)"
                src="@/assets/img/bg_note_tag_active.png"
                style="width: 18px; height: 20px; cursor: pointer"
              />
              <img
                v-else
                v-on:click="ClickTagChecked(item, index)"
                src="@/assets/img/bg_note_tag.png"
                style="width: 18px; height: 20px; cursor: pointer"
              />
            </div>
            &ensp;&ensp;
          </div>
        </CCol></CRow
      >
      <template #modal-footer="{}">
        <div class="">
          <b-button
            v-on:click.prevent="editNote()"
            :disabled="isLoading"
            variant="info"
            class="mr-4"
            >完了
          </b-button>
          <b-button
            variant="secondary"
            @click="closeModalEdit()"
            class="mx-2 back-btn"
            >戻る</b-button
          >
        </div>
      </template>
    </b-modal>
    <!-- Modal Edit Note -->
    <!-- Modal Delete Note -->
    <b-modal
      id="modal-delete-note"
      hide-header
      hide-footer
      style="padding: 20px"
    >
      <div class="d-block text-center px-4" style="padding: 10px">
        <h5>
          削除後、ご利用頂けなくなります。ご注意の上、削除をお願い致します。（復元不可となります。ご留意くださいませ）
        </h5>
      </div>
      <div
        class="d-flex justify-content-center px-4"
        style="padding-bottom: 10px"
      >
        <b-button
          class="mx-2 mt-3 btn btn-danger"
          block
          @click="removeNote(dataModal)"
          >削除する</b-button
        >
        <b-button
          class="mx-2 mt-3 btn btn-secondary"
          block
          @click="$bvModal.hide('modal-delete-note')"
        >
          キャンセル
        </b-button>
      </div>
    </b-modal>
    <!-- Modal Delete Note -->
    <!-- <div class="sidebar-menu mobile" v-if="uiNote !== 1">
      <div class="sidebarList">
        <b-row>
          <b-col>
            <div
              v-bind:class="{
                disabledButton: this.listCata.length > idPageNext,
              }"
            >
              <router-link
                :to="{
                  name: 'DetailPageLibrary',
                  params: {
                    idPage: Number(idPagePrev),
                    idContent: idContent,
                  },
                }"
              >
                <b-icon
                  icon="chevron-left"
                  font-scale="3"
                  style="color: black"
                ></b-icon>
              </router-link>
              <div
                v-bind:class="{
                  disabledDiv: this.listCata.length > idPageNext,
                }"
              >
                前へ
              </div>
            </div>
          </b-col>
          <b-col>
            <router-link :to="{ name: 'library' }">
              <img
                src="@/assets/img/icon_side_library.png"
                alt=""
                style="height: 30px"
              />
              <div style="color: black">コンテンツ</div>
            </router-link>
          </b-col>
          <b-col>
            <div v-b-modal.list-catalog>
              <img
                src="@/assets/img/icon_side_index.png"
                alt=""
                style="height: 30px"
              />
              <div>目次</div>
            </div>
          </b-col>
          <b-col>
            <div v-b-modal.list-note>
              <img
                src="@/assets/img/icon_side_note.png"
                alt=""
                style="height: 30px"
              />
              <div>ノート記録</div>
            </div>
          </b-col>
          <b-col>
            <div>
              <div
                v-bind:class="{
                  disabledButton: this.listCata.length < idPageNext,
                }"
                class="mb-4"
              >
                <router-link
                  :to="{
                    name: 'DetailPageLibrary',
                    params: {
                      idPage: Number(idPageNext),
                      idContent: idContent,
                    },
                  }"
                >
                  <b-icon
                    icon="chevron-right"
                    font-scale="3"
                    style="color: black"
                  ></b-icon>
                </router-link>
                <div
                  v-bind:class="{
                    disabledButton: this.listCata.length < idPageNext,
                  }"
                >
                  次へ
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div> -->
    <!-- Modal create chat-->
    <b-modal id="modal-create-chat" modal-class="" style="min-width: 720px">
      <template #modal-header="{}">
        <h5 class="w-100" style="text-align: center">質問</h5>
        <b-button
          size="sm"
          style="background-color: white; border-color: white"
          class="close"
          @click="closeModalChat()"
        >
          <i class="fas fa-times"></i>
        </b-button>
      </template>
      <CRow>
        <CCol sm="12">
          <input
            class="form-control hoverMouse"
            v-model="valueChat"
            placeholder="メッセージ"
            style="border: 1px solid #fff; height: 66px"
            :maxlength="1000"
          />
        </CCol>
      </CRow>
      <template #modal-footer="{}">
        <div class="">
          <b-button
            v-on:click.prevent="CreateNewChat()"
            :disabled="isLoading"
            variant="info"
            class="mr-4"
            >送信
          </b-button>
          <b-button variant="secondary" @click="closeModalChat()" class="mx-2"
            >キャンセル</b-button
          >
        </div>
      </template>
    </b-modal>
    <b-modal
      id="modal-alert-chat"
      modal-class=""
      style="min-width: 720px"
      hide-header
      hide-footer
    >
      <CRow>
        <CCol sm="12" style="padding: 30px">
          <span>質問送信前に以下ご確認をお願いします。<br /></span>
          <span>・ノート内容は制作者側では見れません。<br /></span>
          <span>「質問」のみしか見れませんのでご留意ください。<br /></span>
          <span
            >※個別の回答ではなく、別のコンテンツやメルマガなどで回答されることがありますので<br
          /></span>
          <span>あらかじめご了承くださいませ。</span><br />
          <div class="w-100 mt-4 d-flex justify-content-center">
            <b-button
              v-on:click.prevent="applyAgree()"
              :disabled="isLoading"
              variant="info"
              class=""
              >内容について同意し送信する
            </b-button>
          </div>
          <div class="remember-form mt-3">
            <input
              v-model="remember_me"
              type="checkbox"
              name="remember"
              id="remember"
              @change="readPopup"
            />
            <label class="ml-1" for="remember"
              >今後はこのアナウンスを表示しない</label
            >
          </div>
        </CCol>
      </CRow>
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import VimeoPlayer from "../../components/vimeoPlayer";
import YoutubePlayer from "../../components/youtubePlayer/index.vue";
import ViewPdf from "../../components/viewPdf/index.vue";
import { Constants } from "../../utils/constants";
// import $ from "jquery";
Vue.directive("scroll", {
  inserted: function (el, binding) {
    let f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener("scroll", f);
      }
    };
    window.addEventListener("scroll", f);
  },
});
export default {
  name: "DetailPageLibrary",
  components: {
    YoutubePlayer,
    VimeoPlayer,
    ViewPdf,
  },
  data() {
    return {
      idContent: null,
      dataCata: null,
      titleCata: localStorage.getItem(Constants.REVIEW_PAGE)
        ? JSON.parse(localStorage.getItem(Constants.REVIEW_PAGE)).page_title
        : "",
      page_Content: localStorage.getItem(Constants.REVIEW_PAGE)
        ? JSON.parse(localStorage.getItem(Constants.REVIEW_PAGE)).page_content
        : null,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      page_id: localStorage.getItem(Constants.REVIEW_PAGE)
        ? JSON.parse(localStorage.getItem(Constants.REVIEW_PAGE)).id
        : null,
    };
  },
  async created() {
    if (this.page_Content) {
      this.page_Content.blocks.map((item) => {
        if (item.type === "table") {
          item.data.contentTbody = item.data.content.filter(
            (item, index) => index > 0
          );
          return item;
        }
        //
        var blocksData = this.page_Content.blocks;
        if (item.type === "toggle") {
          const indexBlockToggle = this.page_Content.blocks.indexOf(item);
          if (item.data.child.length >= item.data.items) return;
          for (let i = 1; i <= item.data.items; i++) {
            console.log(i);
            console.log(blocksData[indexBlockToggle + i]);
            item.data.child.push(blocksData[indexBlockToggle + i]);
            // const indexObj = blocksData.indexOf(
            //   blocksData[indexBlockToggle + i]
            // );
            if (item.data.status === "closed")
              blocksData[indexBlockToggle + i].showDisplay = true;
            blocksData[indexBlockToggle + i].idChild = item.id;
          }
        }
      });
    }
  },
  watch: {
    $route() {
      if (this.page_Content) {
        this.page_Content.blocks.map((item) => {
          if (item.type === "table") {
            item.data.contentTbody = item.data.content.filter(
              (item, index) => index > 0
            );
            return item;
          }
          //
          var blocksData = this.page_Content.blocks;
          if (item.type === "toggle") {
            const indexBlockToggle = this.page_Content.blocks.indexOf(item);
            if (item.data.child.length >= item.data.items) return;
            for (let i = 1; i <= item.data.items; i++) {
              console.log(i);
              console.log(blocksData[indexBlockToggle + i]);
              item.data.child.push(blocksData[indexBlockToggle + i]);
              // const indexObj = blocksData.indexOf(
              //   blocksData[indexBlockToggle + i]
              // );
              if (item.data.status === "closed")
                blocksData[indexBlockToggle + i].showDisplay = true;
              blocksData[indexBlockToggle + i].idChild = item.id;
            }
          }
        });
      }
    },
  },
  computed: {},
  methods: {
    returnPage() {
      if (this.page_id) {
        this.$router.push({
          name: this.$route.params.shopId ? "edit page" : "edit page domain",
          params: { shopId: this.$route.params.shopId, id: this.page_id },
        });
      } else {
        this.$router.push({
          name: this.$route.params.shopId
            ? "create page"
            : "create page domain",
        });
      }
    },
    convertSize(size) {
      if (size) {
        let sizePrefix;
        let formattedSize;

        if (Math.log10(+size) >= 6) {
          sizePrefix = "MB";
          formattedSize = size / Math.pow(2, 20);
        } else {
          sizePrefix = "KB";
          formattedSize = size / Math.pow(2, 10);
        }

        return formattedSize.toFixed(1) + sizePrefix;
      }
    },
    toggleBlock(item) {
      const value = item.data.status === "closed";
      const children = document.querySelectorAll(`div[id-child="${item.id}"]`);
      const { length } = children;

      if (length > 0) {
        for (let i = 0; i < length; i++) {
          children[i].hidden = !value;
          if (children[i].showDisplay !== undefined)
            children[i].showDisplay = !value;
        }
      }
      if (item.data.status === "open") {
        item.data.status = "closed";
      } else {
        item.data.status = "open";
      }
    },
    replaceText(str) {
      return str ? str.replaceAll("&nbsp;", "").trim() : str;
    },
  },
};
</script>

<style lang="scss" scoped>
.detail-content {
  min-height: 100vh;
  .dc-header {
    padding: 30px 50px;
    @media (max-width: 767px) {
      padding: 10px 0;
      font-size: 15px;
      border-bottom: none;
    }
    font-size: 18px;

    border-bottom: 1px solid #c4c4c4;
    a {
      color: #000;
      font-weight: 500;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .dc-main {
    min-height: 100vh;
    width: 100%;
    &-list {
      overflow: hidden;
      width: calc(100vw - 100px);
      padding: 30px 50px;
      background-color: #fffde7;
      @media (max-width: 767px) {
        width: 100%;
        margin-left: 0 !important;
      }
      img {
        max-width: 100% !important;
      }
      &-item {
        margin: 10px 0;
        .item-title {
          font-size: 20px;
          margin-bottom: 20px;
        }
        .item-comtent {
          width: 100%;
        }
      }
    }
  }
}
.dc-note {
  height: 100vh;
  width: 100px;
  background: #fff;
  box-shadow: 0px 3px 6px #00000029;
  &-library {
    height: 15%;
  }
  &-catalog {
  }
  &-note {
  }
  &-step {
    justify-content: space-between;
    padding: 30px 0;
    width: 100%;
    &-item {
      height: 50%;
      width: 100px;
    }
  }
  a {
    color: #000;
    &:hover {
      text-decoration: none;
    }
  }
  .icon-note {
    font-size: 20px;
    cursor: pointer;
    color: #000;
  }
}
.list-tag {
  .item-tag {
    padding: 3px 10px;
    border: 2px solid orangered;
    cursor: pointer;
    background: orange;
    border-radius: 5px;
  }
}
.contents-list {
  &-item {
    padding: 20px;
    cursor: pointer;
    a {
      color: #000;
    }
    .icon-check {
      height: 25px;
      width: 25px;
      background: green;
      color: white;
      position: relative;
      border-radius: 50%;
      margin-right: 10px;
      svg {
        font-size: 18px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .textName {
      font-size: 17px;
      font-weight: 500;
    }
  }
}
.list-note-item {
  padding: 20px;
  // border: 1px solid #000;
  // border-radius: 20px;
  .title-item {
    color: #383434;
    font-weight: 600;
  }
  .list-note-item-pdf {
    color: #383434;
    font-weight: 600;
  }
}
.detail-content {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.nav-bar__left {
  transition: ease-in-out;
}
.sidebar-menu {
  width: 100%;
  overflow: hidden;
  display: block;
  top: auto;
  bottom: 0px;
  left: 0px;
  z-index: 1020;
  position: fixed;
  box-shadow: 0px -3px 6px #00000029;
  .sidebarList {
    background-color: #fff;
    height: 55px;
    z-index: 200;
    border-bottom: 0px;
    font-size: 10px;
    text-align: center;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
  }
}
.height-text {
  height: 100vh;
  width: 30%;
  background: #fff;
  box-shadow: 0px 3px 6px #00000029;
}
.sidebarTtl {
  width: 100%;
  height: 81px;
  font-size: 20px;
  font-weight: normal;
  padding: 30px 0;
  border-bottom: 1px solid #e6e6e6;
  position: relative;
  line-height: 1;
  z-index: 11;
  text-align: center;
}
.isSelected {
  background: #f5f5f5;
}
.customA {
  background: #eee !important;
  border: 1px solid #eee !important;
  border-right: none !important;
  color: #1d1d1d !important;
}
.customA :hover {
  background: #eee;
  border-right: none;
  color: #1d1d1d;
  transition: all 0.3s ease;
  cursor: pointer;
}
.customA ::before {
  content: "";
  display: block;
  width: 18px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: 18px 20px;
  position: absolute;
  top: -1px;
  left: 100%;
  transition: 0.2s ease-out;
}
.customSpan {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 235px;
  display: block;
  height: 18px;
  line-height: 18px;
  font-family: "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ",
    Meiryo, "ＭＳ Ｐゴシック", sans-serif;
  word-break: break-all;
  box-sizing: border-box;
  font-size: 11px;
  padding: 0 0 0 10px;
  cursor: pointer;
}
>>> .modalCustom > .modal-dialog {
  max-width: 720px !important;
  margin: 1.75rem auto;
}
>>> .modalCustom > .modal-dialog > .modal-content > .modal-body {
  height: 465px;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border-top: 1px solid;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
  border-color: #d8dbe0;
  padding: 20px !important;
}
>>> .modalCustom > .modal-dialog > .modal-content > .modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0.75rem;
  border-top: 1px solid;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
  border-color: #d8dbe0;
}
.customTag2 {
  background: #fff;
  border: 1px solid #eee;
  border-right: none;
  color: #1d1d1d;
}
@media (max-width: 767px) {
  >>> .modalCustom > .modal-dialog {
    max-width: 100% !important;
    margin: 1.75rem auto;
  }
  >>> .modalCustom > .modal-dialog > .modal-content > .modal-body {
    width: 100%;
    overflow: auto;
    height: 400px;
  }
}
.customContentNoteMobile {
  width: 95%;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
.hoverMouse:hover {
  border: 1px solid #ccc !important;
}
.hoverMouse:focus {
  border: 1px solid #ccc !important;
  box-shadow: none;
}
.title2 {
  color: #7f7f7f;
  width: 100%;
  text-align: center;
  font-size: 16px;
  margin-top: 2em !important;
  margin-bottom: 2em !important;
  display: flex;
  align-items: center;
  font-family: "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ",
    Meiryo, "ＭＳ Ｐゴシック", sans-serif;
}
.title2::before,
.title2::after {
  border-top: 1px solid;
  content: "";
  flex-grow: 1;
  color: #dfdadadb;
}
.title2:before {
  margin-right: 2rem;
}
.title2:after {
  margin-left: 2rem;
}
.addNote {
  right: 0;
  font-size: 14px;
  display: inline-block;
  color: #555;
  padding: 5px 30px 3px 0;
  background: url(../../assets/img/icon_note.png) 100% center no-repeat;
  background-size: 20px;
}
/* Style the tab */
.tab {
  overflow: hidden;
  background-color: white;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #ccc;
}
.customTitle {
  cursor: pointer;
  border-bottom: 1px solid #d1d1d1;
  padding: 30px 0 30px 20px;
  background-image: url(../../assets/img/icon_arrow01.png);
  background-repeat: no-repeat;
  background-position: 95% center;
  background-size: 9px;
}
.customChatAdmin {
  width: 250px;
  border-radius: 25px;
  background-color: white;
}
.customChatUser {
  width: 250px;
  border-radius: 25px;
  background-color: white;
}
.CustomInputChat {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.icon-no-read {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
}
.remember-form {
  display: flex;
  justify-content: center;
  margin: 0 0 20px;
  align-items: center;
  label {
    font-size: 16px;
    color: #72777c;
    font-weight: bolder;
    margin: unset;
    cursor: pointer;
  }
  input {
    height: 20px;
    width: 20px;
  }
}
.isSelectedScreen {
  color: #2eb85c;
}
.customTitleChapter {
  width: 480px;
  height: 65px;
  display: block;
  text-decoration: none;
  font-size: 16px;
  padding: 20px 15px;
  font-weight: normal;
  display: block;
  line-height: 24px;
  text-align: left;
  height: auto;
}
.customTitlePage {
  height: 65px;
  display: block;
  text-decoration: none;
  font-size: 16px;
  padding: 20px 15px;
  font-weight: normal;
}
.line-catalogue-above {
  display: none;
  width: 2px;
  height: 100%;
  position: absolute;
  background-color: #d1d1d1;
  left: 34px;
  top: 0;
}
.line-catalogue-below {
  display: none;
  width: 2px;
  height: 100%;
  position: absolute;
  background-color: #d1d1d1;
  left: 34px;
  top: 50%;
}
.hoverTittlePage :hover {
  background-color: #ebedef;
}
.checkedPage {
  background-color: #d1d1d1;
}
.customTitlePage::before {
  content: "";
  display: block;
  width: 2px;
  height: 100%;
  position: absolute;
  background-color: #d1d1d1;
  left: 52px;
  top: 0;
  z-index: -1;
}
.UINote {
  justify-content: center;
  margin-left: unset !important;
  // width: 620px !important;
  height: 100vh !important;
}
.UIHeaderNote {
  width: 620px;
}
</style>
<style lang="scss">
body {
  background-color: white !important;
}
</style>
